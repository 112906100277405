import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { FaExternalLinkAlt, FaLock } from 'react-icons/fa';

const NavLink = ({ navLinkData }) => (
  <li className='usa-nav__submenu-item'>
    {navLinkData.isExternal && navLinkData.link !== null ? (
      <a
        href={navLinkData.link}
        target='_blank'
        rel='noopener noreferrer'
        layout={navLinkData.layout}
      >
        {navLinkData.title} <FaExternalLinkAlt /> {navLinkData.isCac && <FaLock />}
      </a>
    ) : (
      <Link to={navLinkData.link || '/'} layout={(navLinkData.layout = 'default')}>
        {' '}
        {navLinkData.title} {navLinkData.isCac && <FaLock />}
      </Link>
    )}
  </li>
);

NavLink.propTypes = {
  navLinkData: PropTypes.object
};

export default NavLink;
