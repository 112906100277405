import { graphql, useStaticQuery } from 'gatsby';

const UseNavItems = () => {
  const data = useStaticQuery(graphql`
    query NavItemsQuery {
      allMedcoeMenuNavJson {
        nodes {
          Category
          Column
          Layout
          Link
          MenuNav_ID
          Portlet_id
          SortOrder
          Title
          isCac
          isExternal
        }
      }
    }
  `);

  return data.allMedcoeMenuNavJson.nodes.map(navitem => ({
    title: navitem.Title,
    link: navitem.Link,
    isCac: navitem.isCac,
    isExternal: navitem.isExternal,
    layout: navitem.Layout,
    category: navitem.Category,
    column: navitem.Column,
    sortOrder: navitem.SortOrder
  }));
};

export default UseNavItems;
