import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
// Hide the shortened nav and only show on mobile

const HomeLinks = styled.section`
  .usa-logo-text__mobile {
    display: none !important;
  }
  @media only screen and (max-width: 480px) {
    .usa-logo__text {
      display: none !important;
    }
    .usa-logo-text__mobile {
      display: initial !important;
    }
  }
`;

const Logo = ({ siteTitle }) => (
  <div className='usa-navbar'>
    <div className='usa-logo' id='extended-logo'>
      <Link to='/' title='Home' aria-label='Home'>
        <StaticImage
          className='usa-logo__amedd-logo usa-logo__amedd-logo__army'
          src='../../images/armylogotransparent.png'
          alt='US Army'
        />
      </Link>
      <StaticImage
        className='usa-logo__amedd-logo'
        src='../../images/medcoe-logo.svg'
        alt='MEDCoE logo'
      />
      <HomeLinks>
        <Link to='/' title='Home' aria-label='Home'>
          <em className='usa-logo__text'>{siteTitle}</em>
        </Link>
        <Link to='/' className='usa-logo__text usa-logo-text__mobile'>
          U.S. Army MEDCoE
        </Link>
      </HomeLinks>
    </div>
    <button className='usa-menu-btn'>Menu</button>
  </div>
);

Logo.propTypes = {
  siteTitle: PropTypes.string.isRequired,
};

export default Logo;
